import React from 'react';
import * as styles from './page-further.module.scss';
import RcCard from "../../components/card/rcCard"

const PageFurther = ( { nodes, outerClassname = '', className = '', title="Weitere Dienstleistungen", limit = 4 }) => {

  return <div className={"row primary " + outerClassname}>

    <div className={"col-12 py-3 text-white " + className}>
      <h4>{title}</h4>
    </div>

      { nodes.filter( (n, i) => i < limit).map(
        n =>  <RcCard to={"/" + n.slug}
                      caption={n.title}
                      className={"mb-4"}
                      innerClassName={"h-100"}
                      description={n.acf.description}
                      img={n.acf?.thumbnail?.localFile?.childImageSharp?.gatsbyImageData} />
      )}

  </div>;

};

export default PageFurther;

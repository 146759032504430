import React from 'react';
import * as styles from './immobilienSection.module.scss';
import { Link } from "gatsby";

import ChevronRightSVG from "../../icons/fontawesome/light/chevron-right.svg"
import ChevronLeftSVG from "../../icons/fontawesome/light/chevron-left.svg"
import { Swiper, SwiperSlide } from "swiper/react"
import ImmobilienSliderCard from "./immobilienSliderCard"
import RcButton from "../../components/button/rcButton"

const ImmobilienSection = ( { showBack = false, address, children, image,
                              otherImmos = [],
                              className = '' } ) => {

  if (!otherImmos || otherImmos?.length === 0) {
    return null;
  }

  return <div className={" row py-4 py-lg-5 " + styles.immoSection + " " + className}>

      <div className="col-12 col-xl-7">

          { showBack &&
            <div className={"mb-4"}>
              <Link to={"/immobilien"} >
                <div className="d-flex align-items-center">
                  <ChevronLeftSVG className={"mr-2 svgFill"} />
                  Zurück zur Übersicht
                </div>
              </Link>
            </div>
          }

          <h4>Gewerbeimmobilien</h4>

        {otherImmos && otherImmos.length > 0 &&
        <div className={"col-12 mt-4 mt-xl-5"}>
          <div className="p-relative">

            <h5 className={"mb-2"}>Gewerbeimmobilien</h5>

            <Swiper className={"w-100 " + styles.swiper} loop={false} spaceBetween={20} slidesPerView={'auto'} navigation grabCursor={true}>

              {otherImmos.map(
                i => {
                  return <SwiperSlide key={i.id} className={styles.sliderCard}>
                    <ImmobilienSliderCard immo={i}/>
                  </SwiperSlide>;
                }
              )}
            </Swiper>


            <div className="text-right">
              <RcButton className={"mt-3"}>
                <Link to={"/immobilien/gewerbe"} className={"d-flex text-white align-items-center"}>
                  <ChevronRightSVG className={"svgFill"} />
                  Alle Gewerbe-Angebote ansehen
                </Link>
              </RcButton>
            </div>

          </div>
        </div>
        }

    </div>
  </div>

};

export default ImmobilienSection;

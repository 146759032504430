import React from 'react';
import Layout from "../page-components/layout"
import { graphql } from "gatsby"
import ImmobilienSection from "../pages-parts/immobilien/immobilienSection"
import BIZOutdoor from "../images/biz-outdoor.jpg"
import ImmobilienFullCard from "../pages-parts/immobilien/immobilienFullcard"
import SEO from "../page-components/seo"
import PageFurtherImmo from "../pages-parts/page/page-further-immo"
import RcDefaultPageTeaser from "../page-components/teaser/rcDefaultPageTeaser"

const ImmobilienPage = ( { data }) => {

  return <Layout>

    <SEO title={"Immobilien"} />

    <RcDefaultPageTeaser>
      <h1>Immobilien</h1>

      { data.wp.texteFRSektionen.acf.immointroduction &&
      <div
        dangerouslySetInnerHTML={
          {
            __html: data.wp.texteFRSektionen.acf.immointroduction
          }
        } />
      }
    </RcDefaultPageTeaser>

    <div className="container h-100 bg-white">

      <PageFurtherImmo title={"Unsere Dienstleistungen"} />

      <ImmobilienSection className={"mb-5"}
                         address={"Franz-Fritsch-Straße 11"}
                         image={ BIZOutdoor }
                         bizImmos={ (data.allStrapiImmos.nodes).filter(n => n.isBIZ) }
                         otherImmos={ (data.allStrapiImmos.nodes).filter(n => !n.isBIZ && n.objektkategorie?.objektart?.buero_praxen) }
      />


      {(data.allStrapiImmos.nodes).filter(n => !n.isBIZ && !n.objektkategorie?.objektart?.buero_praxen ).length > 0 &&
        <h4 className={"text-primary-dark mb-2"}>Private Immobilien</h4>
      }

      { (data.allStrapiImmos.nodes).filter(n => !n.isBIZ && !n.objektkategorie?.objektart?.buero_praxen ).map(
        n => {
          return <ImmobilienFullCard immo={n} sideMargin={false} className={"mb-5"} />
        }
      )}

    </div>

  </Layout>

};

export default ImmobilienPage;

export const query = graphql`query ImmoListPageQuery {

  wp {
    texteFRSektionen {
        acf {
          immointroduction
        }
    }
  }

  allStrapiImmos(
    filter: { title: {ne: "EMPTY"} }
    sort: { fields: created_at, order: DESC }
  ) {
    nodes {
      id
      isBIZ
      objektnr_extern
      objektkategorie {
        objektart {
          haus {
            haustyp
          }
          buero_praxen {
            buero_typ
          }
        }
      }
      geo {
        plz
        ort
      }
      preise {
        nettokaltmiete
        nebenkosten
        zzg_mehrwertsteuer
        freitext_preis
        provisionspflichtig
        kaution
      }
      flaechen {
        nutzflaeche
        gesamtflaeche
        bueroflaeche
        grundstuecksflaeche
        einliegerwohnung
        wohnflaeche
        anzahl_zimmer
        anzahl_schlafzimmer
        anzahl_badezimmer
        anzahl_sep_wc
        anzahl_balkone
        balkon_terrasse_flaeche
        anzahl_terrassen
        anzahl_wohneinheiten
        kellerflaeche
        ladenflaeche
        freiflaeche
      }
      ausstattung {
        klimatisiert
        gartennutzung
        kabel_sat_tv
        barrierefrei
        swimmingpool
        dv_verkabelung
        teekueche
        wg_geeignet
        stromanschlusswert
        hallenhoehe
      }
      freitexte {
        objekttitel
        lage
        objektbeschreibung
      }
      Anhang {
        gruppe
        daten {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
}
`;

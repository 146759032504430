import React from 'react';
import * as styles from './immobilienSliderCard.module.scss'

import RcImmoAreaBadge from "../../helper-components/immo/rcImmoAreaBadge"
import RcImmoPrice from "../../helper-components/immo/rcImmoPrice"

import { Link } from "gatsby";
import RcImmoHeaderImage from "../../helper-components/immo/rcImmoHeaderImage"

const ImmobilienSliderCard = ( { immo } ) => {

  return <Link to={"/immobilien/" + immo.id}>
    <div className={styles.sliderCard}>

      <RcImmoHeaderImage className={styles.sliderCardImage} immo={immo} />
      <div className={styles.sliderGradient} />

      <div className={styles.sliderCardPros + " text-white pb-2 px-2 d-flex align-items-end justify-content-between"}>
        <RcImmoAreaBadge className={styles.property} immo={immo} />
        <RcImmoPrice className={styles.property} immo={immo} />
      </div>

    </div>
  </Link>


};

export default ImmobilienSliderCard;

import React from 'react';
import { graphql, useStaticQuery } from "gatsby"
import PageFurther from "./page-further"

const PageFurtherImmo = ( { className = '', removeId, title = undefined }) => {

  const data = useStaticQuery(graphql`
      query PageFurtherImmoQuery {
        allWpPage(filter: {acf: {art: {in: [ "immo" ] }}}) {
        
          nodes {
            id
            acf {
              art
              description
              thumbnail {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            slug
            title
          }
        }
      }
      `);

  return <PageFurther title={title}
                      className={className + " text-black"}
                      outerClassname={"bg-white py-3"}
                      limit={100}
                      nodes={data.allWpPage.nodes.filter(n => n.id !== removeId )} />

};

export default PageFurtherImmo;
